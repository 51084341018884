body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.theme-dark {
  --dark-text: #000;
  --light-text: #000;  
  --dark-background: #343a40;
  --light-background: #6c757d;
  --accent: #0095fc;
  --button-border: #B8DBD9;
  --backgroud-color: #708091;
  --border: 1.3px solid #D4E0E6 !important;
  --input-color: #F9FAFB;
}
/*#D4E0E6*/
.theme-light {
  --dark-text: #000;
  --light-text: #000;
  --dark-background: #CECECE;
  --light-background: #ffffff;
  --accent: #0095fc;
  --button-border: #5E4B56;
  --backgroud-color: #F9FAFB;
  --border: 1.3px solid #D4E0E6 !important;
  --input-color: #F9FAFB;
}

html, #root {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

nav {
  background-color: var(--dark-background);
  color: var(--light-text);
}

.app {
  background-color: var(--backgroud-color);
}
