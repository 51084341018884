:root {
    --progress-background: #fff;
    --progress-bar: green;
  }

.progress {
    position: absolute;
    background-color: var(--progress-bar);
    height: 100%;
    border-radius: 3px;
}

.file-item {
    width: fit-content;
    border: 1px solid black;
    border-radius: 4px;
    position: relative;
    margin: 3px;
    display: inline-block;
    background-color: var(--progress-background);

}

.file-item-container {
    margin-top: 5px;
    list-style-type: none;
    border: 1px solid gray;
    border-radius: 6px;
    padding: 2px;
}