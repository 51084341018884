input {
  border-radius: 5px;
  border: var(--border);
  background-color: var(--input-color);
  /*
  border: 1px solid white;
  */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.inputs div {
  /*
  display: flex;

  width: fit-content;
  */
  display: inline-block;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 15px;
}

.inputs div{
  padding: 5px 15px;
}

.inputs div [class="input-number"] {
  padding: 5px 15px;
}

.inputs div [class="input-checkbox"] {
  padding: 5px 15px;
}

.inputs div div {
  padding: 5px 5px;
}

.input-date {
  width: 100%;
  text-align: center;
}

.input-time {
  width: 100px;
  text-align: center;
}

.input-text {
  width: 100%;
}

.input-text input{
  width: 100%;
}

.input-location {
  width: fit-content;
  margin: 0.5em;
  padding: 0 0.5em 0.5em 0.5em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input-location label {
  border-radius: 5px;
  border: 1px solid #000000;
  padding: 5px;
  display: inline-block;
  margin: 5px;
  width: fit-content;
  user-select: none;
  cursor: pointer;
}

.input-location input {
  border-radius: 5px;
  padding: 5px;
  border: 1.3px solid #000000;
  display: inline-block;
  margin: 5px;
  width: fit-content;
}

.input-checkbox {
  text-align: center;
  vertical-align: text-top;
  display: inline-block;
  user-select: none;
  margin: 0px;
}

.input-checkbox div {
  padding: 5px;
  cursor: pointer;
}


.input-checkbox input[type="checkbox"] {
  bottom: 3px;
  position: relative;
  vertical-align: text-bottom;
  margin-right: 5px;
  border-radius: 5px;
  /*
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 1.3em;
  height: 1.3em;
  vertical-align: middle;
  text-align: center;
  */
}

.input-checkbox input[type="checkbox"]:checked {
  background-color: var(--accent);
}
/*
input[type="checkbox"]#display {
  width: 20px; /* Set width and height *w/
  height: 20px;
  -webkit-appearance: none; /* Remove default appearance *w/
  -moz-appearance: none;
  appearance: none;
  border: 5px solid #ccc; /* 5px border *w/
  border-radius: 5px; /* Rounded corners *w/
  outline: none; /* Remove default focus style *w/
  cursor: pointer; /* Show pointer cursor *w/
}

/* Style the checked state *w/
input[type="checkbox"]#display:checked {
  background-color: #007bff; /* Example background color *w/
}

/* Style the checkmark *w/
input[type="checkbox"]#display::after {
  content: ""; /* Empty content *w/
  display: inline-block;
  width: 10px; /* Set size of checkmark *w/
  height: 10px;
  border: 2px solid #fff; /* White border around checkmark *w/
  border-top: none; /* Hide top border *w/
  border-right: none; /* Hide right border *w/
  transform: rotate(-45deg); /* Rotate checkmark *w/
  margin-left: 5px; /* Adjust position *w/
}
*/






.input-number {
  display: inline-block;
  padding: 10px;
}

.input-number span {
  display: block;
}

.input-number input {
  width: 100px;
  text-align: center;
  vertical-align: text-top;
  display: inline-block;
  padding: 5px;
}

input:focus-visible {
  outline: none;
}

#drop_zone {
  width: 100%;
}

.Toastify__toast-container--top-right {
  top: 70px;
}

.custom {
  width: 100%;
}