/* CSS */
.toggle-container {
    position: relative;
    width: 60px;
    height: 50px;
}

.toggle--checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle--label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px; /* Added to make the toggle round */
    border: 1px solid var(--light-background);
}

.toggle--label:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; /* Added to make the toggle round */
}
/*
.toggle--checkbox:checked + .toggle--label {
    background-color: var(--dark-background);
}
*/
.toggle--checkbox:checked + .toggle--label:before {
    transform: translateX(28px);
}
